import React from "react";
import { Form } from "react-bootstrap";

function CheckboxInput({ meta, formInstance, isInvalid }) {
  const { key, rules = {}, className, labelText } = meta;
  const { register } = formInstance;

  return (
    <Form.Check
      type="checkbox"
      label={labelText}
      name={key}
      isInvalid={isInvalid}
      className={className}
      {...register(key, { ...rules })}
    />
  );
}

export default CheckboxInput;
