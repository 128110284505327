import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const orderTable = async (data) => {
  return await adminApi({
    endpoint: endpoints.ORDERING,
    method: "post",
    data: data,
  });
};
