import React from "react";
import { eventBus } from "../../services/eventBus";
import { getPaginationParams, getUrlSearchParams } from "../../utils/urls";
import FormBuilder from "../form/formBuilder/FormBuilder";

const Filter = ({ filtersMeta, queryService, params, setParams }) => {
  const handleSubmit = (data) => {
    Object.keys(data).forEach((key) => (!data[key] ? delete data[key] : {}));
    const newParams = { ...getPaginationParams(params), ...data };
    setParams(newParams);
    // queryService(newParams);
  };

  const reset = () => {
    setParams({});
    // queryService({ ...getPaginationParams(params) });
  };

  const submitButton = {
    className: "btn btn-primary rounded-0 me-3 d-inline-block  m-1",
    label: "Search",
    isDisabled: false,
  };

  return (
    <div className="table_filter_container">
      <div className="filter_form">
        <FormBuilder
          className="d-flex justify-content-around filter-form col-12"
          onSubmit={handleSubmit}
          fields={filtersMeta}
          submitButton={submitButton}
          doNotReset={true}
          defaultValues={getUrlSearchParams(params)}
          resetEvent="reset_filter_form"
          actions={[
            <button
              key="Reset"
              className="me-3 btn btn-primary rounded-0 search_button_filter m-1"
              type="button"
              onClick={() => {
                eventBus.publish("reset_filter_form");
                reset();
              }}
            >
              Reset
            </button>,
          ]}
        />
      </div>
    </div>
  );
};

export default Filter;
