// eslint-disable-next-line no-unused-vars
export const handleError = (error) => {
  const response = {
    code: 503,
    error: error.message,
    data: undefined,
    success: false,
  };
  return response;
};
