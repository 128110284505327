import React from "react";
import { Form } from "react-bootstrap";

const InputError = ({ error }) => {
  if (!error) return null;
  console.log(error);

  if (error?.type === "required") {
    return (
      <Form.Control.Feedback style={{ display: "block" }} type="invalid">
        {error.message || "This is required"}
      </Form.Control.Feedback>
    );
  } else
    return (
      <Form.Control.Feedback style={{ display: "block" }} type="invalid">
        {error.message?.length ? error.message : "This input is Invalid"}
      </Form.Control.Feedback>
    );
};

export default InputError;
