import React from "react";
import { Form } from "react-bootstrap";

const PageSize = ({ pageSize, onChange }) => {
  return (
    <>
      <Form.Select
        className=""
        name="pageSizeValue"
        onChange={(e) => {
          onChange(1, parseInt(e.target.value));
        }}
        value={pageSize}
      >
        <option value="10">Show 10</option>
        <option value="20">Show 20</option>
        <option value="0">Show All</option>
      </Form.Select>
    </>
  );
};

export default PageSize;
