import React from "react";
import { Form } from "react-bootstrap";

function RadioInput({ meta, formInstance, isInvalid }) {
  const { key, rules = {}, options, childrenClassName } = meta;
  const { register } = formInstance;

  return (
    <div className={childrenClassName}>
      {options.map((option) => (
        <Form.Check
          key={option.value}
          type="radio"
          label={option.label}
          value={option.value}
          name={key.replace(/[0-9]/g, "")}
          className="col-4"
          isInvalid={isInvalid}
          {...register(key, { ...rules })}
        />
      ))}
    </div>
  );
}

export default RadioInput;
