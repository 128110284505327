import { useNavigate } from "react-router-dom";

function LinkOpener({ openerText, openerClassName = "", navigateTo }) {
  const navigate = useNavigate();
  return (
    <div>
      <p
        className={`${openerClassName} mb-0`}
        onClick={() => navigate(navigateTo)}
      >
        {openerText}
      </p>
    </div>
  );
}

export default LinkOpener;
