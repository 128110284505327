import ReactPaginate from "react-paginate";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PaginatedItems = ({ pagesCount, current, onChange }) => {
  return (
    <>
      <ReactPaginate
        nextLabel={
          <FontAwesomeIcon
            className="align-middle"
            icon={faArrowRight}
            fixedWidth
          />
        }
        onPageChange={(e) => {
          onChange(e.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pagesCount}
        previousLabel={
          <FontAwesomeIcon
            className="align-middle"
            icon={faArrowLeft}
            fixedWidth
          />
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        forcePage={parseInt(current) - 1}
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination mb-0"
        activeClassName="active"
      />
    </>
  );
};

export default PaginatedItems;
