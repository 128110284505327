import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search projects…",
      "Welcome back": "Welcome back",
    },
  },
  bn: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "ফিরে আসার জন্য স্বাগতম",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
