import { useContext } from "react";
import { Modal } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import { eventBus } from "../../services/eventBus";
import FormBuilder from "../form/formBuilder/FormBuilder";

const ModalForm = ({
  isVisible,
  setIsVisible,
  title,
  fields,
  submitButton,
  onSubmit,
  defaultValues,
  doNotReset = false,
  submitButtonText,
  appendPayload = {},
  refreshEvent,
  successMessage,
  errorMessage,
  size,
}) => {
  const notyf = useContext(NotyfContext);

  const handleSubmit = async (data) => {
    try {
      if (!onSubmit) {
        setIsVisible(false);
        return;
      }
      const res = await onSubmit({ ...data, ...appendPayload });
      if (!res.success) throw res;
      setIsVisible(false);
      if (refreshEvent) eventBus.publish(refreshEvent);
      const message = successMessage || "Something went wrong";
      notyf.open({
        type: "success",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    } catch (error) {
      const message = error.error || errorMessage || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    // <Loader isLoading={isLoading || !!performQuery} minHeight={false}>
    <Modal
      size={size}
      show={isVisible}
      onHide={() => setIsVisible(false)}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body className="m-3">
        <FormBuilder
          onSubmit={handleSubmit}
          fields={fields}
          defaultValues={{
            ...defaultValues,
          }}
          submitButton={submitButton}
          doNotReset={true}
          submitButtonText={submitButtonText}
        />
      </Modal.Body>
    </Modal>
    // </Loader>
  );
};

export default ModalForm;
