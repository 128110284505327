export const getLocalStorageData = (key) => {
  if (typeof window !== "undefined") {
    const data = localStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }
};

export const setLocalStorageData = (key, data) => {
  const strData = typeof data === "string" ? data : JSON.stringify(data);
  return localStorage.setItem(key, strData);
};

export const removeLocalStorageData = (key) => {
  localStorage.removeItem(key);
};
