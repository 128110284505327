import endpoints from "../../constants/endpoints";
import { convertToFormData } from "../../utils/formUtils";
import { adminApi } from "./client";

export const uploadFile = async (data, folder) => {
  const payload = {
    document: data,
    folder: folder,
  };

  return await adminApi({
    endpoint: `${endpoints.FILE_UPLOAD}`,
    method: "post",
    data: convertToFormData(payload),
  });
};
