import React from "react";
import { Form } from "react-bootstrap";

function DateInput({ meta, formInstance, isInvalid }) {
  const { key, placeHolder, rules = {} } = meta;
  const { register } = formInstance;

  return (
    <Form.Control
      type="Date"
      placeholder={placeHolder || ""}
      isInvalid={isInvalid}
      {...register(key, { ...rules })}
    />
  );
}

export default DateInput;
